<template>
  <!-- VACANCY -->
  <div class="career-vacancy">
    <div class="container">
      <h2 class="career-vacancy__title big-title">
        {{ $t("currentVacancies") }}
      </h2>

      <button class="career-vacancy__filter-btn" @click="showFilter">
        {{ $t("filter") }}
      </button>

      <div class="career-vacancy__filter-title">
        {{ $t("filter") }}
      </div>

      <div class="career-vacancy__content">
        <vacancy-filter
          v-model="filtered"
          :active="isShowFilter"
          @close="closeFilter"
        />

        <vacancy-list :filters="filtered" />
      </div>
      <!-- Container -->
    </div>
    <!-- Vacancy -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { actionTypes } from "@/store/modules/vacancy";
export default {
  name: "VacancyBlock",
  data() {
    return {
      filtered: null,
      isShowFilter: false,
    };
  },
  components: {
    VacancyFilter: () => import("@/components/vacancy/VacancyFilter.vue"),
    VacancyList: () => import("@/components/vacancy/VacancyList.vue"),
  },
  mounted() {
    // this.changeFiltered(this.filtered);
  },
  watch: {
    filtered: {
      handler() {
        // this.changeFiltered(this.filtered);
      },
    },
  },
  methods: {
    ...mapActions("vacancyModule", {
      changeFiltered: actionTypes.changeFilteredVacancy,
    }),
    showFilter() {
      this.isShowFilter = !this.isShowFilter;
    },
    closeFilter() {
      this.isShowFilter = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
